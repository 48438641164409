import React, {useState, useEffect} from "react"
import {Container,Form,Navbar,Nav} from 'react-bootstrap';
import InputRange from 'react-input-range';
import { Link } from "gatsby"
import NavLink from  "../../components/NavLink/Custom_Presentation"; 
import Header from  "../../components/Common/Header";
import SEO from "../../components/seo"

import {config} from '../../components/Common/constant';

import Payment from '../../components/modules/payment';
import CompareCost from '../../components/modules/compare_cost';

import { GET_ARTICLE } from "../../queries/common_use_query";

import { useContainerContext } from "../../store/ContainerContext";

import { UpdateProperty } from "../../queries/api_links";



const Fees = () => {
  
  const context = useContainerContext();
  const { payment_fees, property_data } = context.state;
  const { propertyDetDispatch } = context.actions;

  const [ article_data, setArticleData ] = useState('');
  const { loading, error, data } = GET_ARTICLE('payment-options');

  //const [marketing_price, setMarketingPrice] = useState(300000);
  const [marketing_price, setMarketingPrice] = useState(0);

  useEffect(()=>{
      setArticleData(data && data.articles[0]);
  }, [data]);

    const handClick = () => {
      //alert("test");
      var get_property_id = property_data && property_data.id;
      const updateQry = UpdateProperty(get_property_id, {payment_option: payment_fees}).then(res => {
        //console.log("payemnt_res", res);
        if(res && res.status === 200){
          //console.log("payemnt_res", res.data);
          propertyDetDispatch(res.data)
        } else{

        }
      });
    }

    const recommendedPrice = (marketing_price) =>{
      //let value = event.target.value; 
      //let name = event.target.name;   

      let value = marketing_price;

      setMarketingPrice(value > 0 ? value : 0);
    }

    //console.log("product_specification", Object.keys(property_data.product_specifications).length);
    //console.log("marketing_price 2", marketing_price);


    return <>
      <SEO title={article_data && article_data.Meta_Title} description={article_data && article_data.Meta_Description} />

      

      <div className="pre-wrapper">
        
        <Header property_data={property_data}/>

        <div className="section-wrapper custom_present_inner pt-0">
          <Payment handClick={handClick} property_data={property_data} handleChange={recommendedPrice} marketing_price={marketing_price} />

          <CompareCost property_data={property_data} marketing_price={marketing_price} />
        </div>

        <NavLink tab_name="Fees" prevLink={property_data && property_data.product_specifications ? config.custom_marketing_campaign : config.custom_comparables} nextLink={config.custom_contract_form} handClick={handClick} />

      </div>
    </>
}

export default Fees
